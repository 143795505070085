body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: "Wallop";
  src: url(/static/media/Wallop-Regular.0bc39802.ttf);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Wallop Semi bold";
  src: url(/static/media/Wallop-SemiBold.f8962522.ttf);
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Wallop Medium";
  src: url(/static/media/Wallop-Medium.33c63b27.ttf);
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Wallop Bold";
  src: url(/static/media/Wallop-Bold.cdb35f17.ttf);
  font-weight: normal;
  font-style: normal;
}
.person-lit {
  color:white;
}
.triangle {
  color:white;
  -webkit-filter: brightness(0%);
          filter: brightness(0%);
}

 .top-bar-tab{
  color: #ffffff80;
  cursor:pointer;
}
 .top-bar-container{
  display: flex;
  justify-content: space-between;
  color:white;
  font-size:24px;
  padding:20px;
 
  @media screen and (max-width: 576px) {
    padding:0px 20px;
    font-size:20px;
    & img{
      width:75%;
    }
  }
  @media screen and (max-height: 576px) {
    padding:0px 20px;
    font-size:20px;
    & img{
      width:75%;
    }
  }
}
.topbar{
  top:0px;
  left: 0px;
  background-color: #1a1e28;
  width:100%;
  padding: 10px 0px;
 
}
.problem-wrap{
  position: absolute;
  top:3px;
  right: 0px;
  padding: 10px 0px;
  
}
.font-f{
  color: #FFFFFF;
}
.twinwrap {
  display: flex;
  flex-direction: row;
  grid-column-gap: 1vh;
  -webkit-column-gap: 1vh;
          column-gap: 1vh;
  margin-right: 1vh;
}
.twinbtns22 {
  border-radius: 2em;
  background-color: #9afee0;
  padding-bottom: 0vh;
  padding-left: 1vh;
  padding-right: 1vh;
  width: 18vh;
  height: 6vh;
  font-size: 1.5vh;
  font-weight: 600;
  border: #9afee0;
  margin-top: 7px;
}
.twinbtns0 {
  border-radius: 2em;
  background-color: #9afee0;
  padding-bottom: 0vh;
  padding-left: 1vh;
  padding-right: 1vh;
  width: 18vh;
  height: 4vh;
  font-size: 1.5vh;
  font-weight: 600;
  border: #9afee0;
}
.twinbtns1 {
  border-radius: 2em;
  background-color: #30343D;
  padding-bottom: 0vh;
  padding-left: 1vh;
  padding-right: 1vh;
  width: 18vh;
  height: 4vh;
  font-size: 1.5vh;
  font-weight: 600;
  border: #30343D;
}
.twinbtns2 {
  border-radius: 2em;
  background-color: #30343D;
  padding-bottom: 0vh;
  padding-left: 1vh;
  padding-right: 1vh;
  width: 18vh;
  height: 4vh;
  font-size: 1.5vh;
  font-weight: 600;
  border: #30343D;
}
.twinbtns-loggedin{
  border-radius: 2em;
  background-color: #30343D;
  padding-left: 1vh;
  padding-right: 1vh;
  font-size: 1.5vh;
  font-weight: 600;
  margin-top: 3px;
  width: 18vh;
  height:4vh;
  border: #30343D;
}
.nomarkers::visited {
  text-decoration: none;
  color: black;
}
.nomarkers {
  text-decoration: none;
}
.twin0{
  color:#FEFFFA;
}
.twin4{
  color: black;
}
.twin01{
  color:#FEFFFA;
  margin-top: 2px;
}
.twin1{
  color:#FEFFFA;
}
.footer {
  width: 100%;
  background: #1C1E23;
  display: flex;
  justify-content: center;
  align-items: center;
  color:#6A7280;
  padding: 50px 30px;
  font-family: "Wallop";
  font-size:18px;
  font-weight: 700;
}
.loadingPage {
  width:100%;
  background: #1C1E23;
}
.section_container{
  max-width:1320px;
  margin:0 auto;
  padding:10px;
  @media screen and (max-width: 1200px) {
    max-width: 1140px;
  }
  @media screen and (max-width: 992px) {
    max-width: 960px;
  }
  @media screen and (max-width: 768px) {
    max-width: 720px;
  }
  @media screen and (max-width: 576px) {
    max-width: 540px;
  }
  @media screen and (max-width: 420px) {
    max-width: 100%;
  }
  
  
}
.cards-promo-wrap {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-self: center;
  justify-content: center;

}
.cards-promo{
  align-self: center;
  margin-top: 30px;
  margin-bottom: -30px;

}
.btn{
  background: #9AFEE5;
  border-radius: 30px;
  padding: 10px 20px;
  color:black;
  font-family: "Wallop";
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  @media screen and (max-width: 768px) {
    font-size: 16px;
    padding: 6px 15px;
  }
}
.signup-btn{
  background: #9AFEE5;
  border-radius: 30px;
  padding: 10px 20px;
  color:black;
  font-family: "Wallop";
  font-size: 18px;
  line-height: 20px;
  @media screen and (max-width: 768px) {
    font-size: 20px;
    padding: 6px 15px;
  }
}




.marginbottom_50_responsive{
    margin-bottom: 50px !important;
    @media screen and (max-width: 576px) {
      margin-bottom: 5px !important;
    }
}
.marginbottom_30_responsive{
  margin-bottom: 30px !important;
  @media screen and (max-width: 576px) {
    margin-bottom: 10px !important;
  }
}

.padding-top-100{
  padding-top:100px !important;
}
.padding-tb-none{
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.padding-20{
  padding:20px;
}
.margin-bottom-10{
  margin-bottom: 10px !important;
}
.margin-bottom-15{
  margin-bottom: 15px !important;
}
.margin-top-10{
  margin-top: 10px !important;
}
.margin-top-15{
  margin-top: 15px !important;
}
.margin-top-30{
  margin-top: 30px !important;
}
.margin-left-5{
  margin-left: 5px !important;
}
.margin-left-10{
  margin-left: 10px !important;
}
.margin-left-20{
  margin-left: 20px !important;
}
.margin-left-30{
  margin-left: 30px !important;
}
.margin-left-minus-15{
  margin-left: -15px !important;
}
.margin-left-minus-20{
  margin-left: -15px !important;
}
.margin-bottom-minus-25{
  margin-bottom: -25px !important;
  @media screen and (max-width: 768px) {
    margin-bottom: -10px !important;
  }
}
.margin-left-50-percent{
  margin-left: 50% !important;
}
.line-height-40{
  line-height: 40px !important;
}
.line-height-50{
  line-height: 50px !important;
}
.line-height-20{
  line-height: 20px !important;
}
.line-height-30{
  line-height: 30px !important;
}
.top-left{
  position: absolute;
  top: 0px;
  left: 2px;
}
.top-right{
  position:absolute;
  top: 15px;
  right: 0px;
}
.display-flex{
  display: flex;
  display: -webkit-flex;
}
.flex-align-center{
  align-items: center;
}
.flex-align-start{
  align-items: flex-start !important;
}
.flex-align-end{
  align-items: flex-end !important;
  @media screen and (max-width: 576px) {
    align-items: center !important;
    justify-content: center !important;
  }
}
.flex-justify-center{
  justify-content: center;
}
.flex-space-between{
  justify-content: space-between;
}
.flex-column{
  flex-direction: column;
}
.flex-wrap{
  flex-wrap: wrap;
}
.flex-space-around{
  justify-content: center;
  justify-content: space-evenly;
  justify-content: space-around;
}


.font-size-24{
  font-size: 24px !important;

  @media screen and (max-width: 768px) {
    font-size: 18px !important;
  }
  @media screen and (max-width: 576px) {
    font-size: 14px !important;
  }
}
.font-size-22{
  font-size: 22px !important;

  @media screen and (max-width: 768px) {
    font-size: 18px !important;
  }
  @media screen and (max-width: 576px) {
    font-size: 14px !important;
  }
}
.font-size-20{
  font-size: 20px !important;

  @media screen and (max-width: 768px) {
    font-size: 16px !important;
  }
  @media screen and (max-width: 576px) {
    font-size: 12px !important;
  }
}
.font-size-36{
  font-size: 18px !important;


}
.font-size-40{
  font-size: 40px !important;

  @media screen and (max-width: 768px) {
    font-size: 36px !important;
  }
  @media screen and (max-width: 576px) {
    font-size: 32px !important;
  }
}
.font-size-18{
  font-size: 18px !important;
  @media screen and (max-width: 576px) {
    font-size: 16px !important;
  }
}
.font-size-17{
  font-size: 17px !important;
  @media screen and (max-width: 576px) {
    font-size: 13px !important;
  }
}
.font-size-16{
  font-size: 16px !important;

  @media screen and (max-width: 576px) {
    font-size: 13px !important;
  }
}
.font-size-14{
  font-size: 14px !important;

  @media screen and (max-width: 576px) {
    font-size: 11px !important;
  }
}
.font-size-12{
  font-size: 12px !important;

  @media screen and (max-width: 576px) {
    font-size: 10px !important;
  }
}
.font-left{
  text-align: start !important;

}
.font-bold{
  font-family: "Wallop Bold";
}

.font-semibold{
  font-family: "Wallop Semi bold";
}

.font-medium{
  font-family: "Wallop Medium";
}

.border-radius-15{
  border-radius: 15px !important;
}
.border-radius-25{
  border-radius: 25px !important;
}
.border-radius-100{
  border-radius: 100% !important;
}

.position-absolute{
  position:absolute;
}

.position-relative{
  position:relative;
}

.top-5{
  top:5px;
}

.left-5{
  left:5px;
}

.width-100{
  width:100%;
}
.height-100{
  height:100%;
}
.max-width-100{
  max-width:100%;
}
.height-100vh{
  height: 100vh !important;
}
.color-blue{
  color: #8df3db; 
}
.color-white{
  color: white; 
}
.color-gray{
  color: #b1b1b1;
}

.btn{
  border-radius: 25px;
  font-size:16px;
  line-height: 18px;
  font-weight: 800 !important;
  font-family: "Wallop Bold";
  @media screen and (max-width: 768px) {
    font-size:12px;
    letter-spacing: -0.8px;
  }
}
.btn-blue{
  background: #9AFEE5;
  color: #000000;
  padding: 5px 20px;
  @media screen and (max-width: 768px) {
    padding: 5px 10px;
  }
}
.btn-dark{
  background: #464A51;
  color: #FFFFFF;
  padding: 5px 20px;
  @media screen and (max-width: 768px) {
    padding: 5px 10px;
  }
}

.text-inline{
  text-overflow: ellipsis;
  white-space: nowrap;

  overflow: hidden;
}
.emoji-inline{
  white-space: nowrap;
}
.cursor-pointer{
  cursor: pointer !important;
}

.font-weight-600{
  font-weight: 600;
}

.profile-img-box{
  width:200px;
  height: 200px;
  background-position: center;
  background-size: cover;
  border-radius: 100%;
}

.emoji-commentary{
  display:flex;
  flex-direction: row;
  grid-column-gap: 8px;
  -webkit-column-gap: 8px;
          column-gap: 8px;
  align-content: center;
  align-items: center;
}
.emojis-wrap{
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border-radius: 2em;
  border: 0.1em solid;
  border-color: white;
  grid-column-gap: 4px;
  -webkit-column-gap: 4px;
          column-gap: 4px;
  margin-top: .05em;
}
.emojis-wrap-background{
  background-color: white;
  border-radius: 2em;
  border: 0.05em solid;
  border-color: white;
  line-height: .92;
}
.card-image{
  aspect-ratio: 1/1;
  object-fit: cover;
  margin-top: -20px;
}
.cardtextwrap {
  display: flex;
  flex-direction: column;
  line-height: 1.7;
  justify-content: center;
  text-overflow: ellipsis;

}

.emojis-wrap-case1{
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: white;
  border-radius: 2em;
  border: 0.1em solid;
  border-color: white;
  grid-column-gap: 4px;
  -webkit-column-gap: 4px;
          column-gap: 4px;
}

.log-out{
  align-self: center;
  margin-top: -8px;
  margin-bottom: 8px;
}
.spotify-logo-trackcard{
  margin-top: -15px;
}

.next-line-space{
  white-space: initial;
}
.productscreenshotwrap{
  margin-top: 18px;
}
.tooltip-cards{
  align-self: center;
  width: 60%;
  height:60%;
  margin-bottom: -1px;
  margin-left: -3px;
}
.productscreenshot{
  margin-top: 50px;
  margin-bottom: -80px;
}
.drop-shadow{
  -webkit-filter: drop-shadow(2px 2px 2px #222);
  filter: drop-shadow(2px 2px 2px #222);
  width: 70%;
  height: 70%;
}
.bs-person{
  margin-top: 6px;
  margin-bottom: -3px;
}
.stat-empty-mob{
  top:0;
  left:0;

}
.stat-mob{
  width: 140px;
}
.statswrap{
  width: 140px;
  margin-top: -5px;
}
.username-mob{
  align-self: center;
}
.profile-area-mob{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profile-img-box-mob{
  width:200px;
  height: 200px;
  background-position: center;
  background-size: cover;
  border-radius: 100%;
  margin-bottom: 8px;
}
.copy-sec{
  display: flex;
  flex-direction: column;
}
.margin-left-link{
  margin-left: 40% !important;
}
.copie{
  margin-bottom: -2px;
  margin-left: 5px;
}


.twinbtns-loggedin-mob{
  border-radius: 2em;
  background-color: #30343D;
  padding-left: 1vh;
  padding-right: 1vh;
  font-weight: 600;
  font-size: 10px;
  width: 100px;
  position: relative;
  top: -7.8px;
  height: 30px;
  border: #30343D;
}
.bs-person-mob{
  margin-bottom: -4px;
}

.log-out-mob{
  align-self: center;
}


.editTextMob{
  font-size: 10px;
  margin-top: 10px;
  cursor:pointer;
}

@font-face {
  font-family: 'Wallop';
  src: local('Wallop'), url(/static/media/Wallop-Regular.7811d7e9.otf) format('opentype');
}
.header0 {
  background-color: #1a1e28;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 3vw;
  grid-column-gap: 2vh;
  -webkit-column-gap: 2vh;
          column-gap: 2vh;
  font-weight: 900;
  height: 6vh;
  color: #9afee0;
}
.header1 {
  background-color: #1a1e28;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 10%;
  font-size: 8vw;
  font-family: 'Wallop';
  font-style: normal;
  font-weight: 600;
  color: #fefffa;
}
.nodec-buttons-text {
  color:black;
}
.nomarkers::visited {
  text-decoration: none;
  color: black;
}
.nomarkers {
  text-decoration: none;
}
.twinbtns{
  margin-top: 1vh;
  background-color: #DAAAF6;
  border-radius: 10%;
  width:15vh;
  height: 5vh;
  font-size: 1.7vh;
  font-weight: 600;
  border:#DAAAF6;
}
.popularitywrapper{
  margin-left: 3vh;
  margin-top: -10vh;
}
.tooltip{
  font-size: 2vh;
  color: #DAAAF6;
  margin-bottom: 5vh;
  margin-left: 1vh;
}
.prewrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
}
.number12 {
  margin-top: -10vh;
  font-weight: bold;
  font-size: 9vh;
  color: white;
}
.number0 {
  margin-top: -10vh;
  font-weight: bold;
  font-size: 9vh;
  color: #9afee0;
}
.number1 {
  margin-top: -10vh;
  font-weight: bold;
  font-size: 9vh;
  color: #9afdc7;
}
.number2 {
  margin-top: -10vh;
  font-weight: bold;
  font-size: 9vh;
  color: #9afdae;
}
.number3 {
  margin-top: -10vh;
  font-weight: bold;
  font-size: 9vh;
  color: #9cfe97;
}
.number4 {
  margin-top: -10vh;
  font-weight: bold;
  font-size: 9vh;
  color:  #b4fe97;
}
.number5 {
  margin-top: -10vh;
  font-weight: bold;
  font-size: 9vh;
  color: #cdfe97;
}
.number6 {
  margin-top: -10vh;
  font-weight: bold;
  font-size: 9vh;
  color: #e7ff95;
}
.number7 {
  margin-top: -10vh;
  font-weight: bold;
  font-size: 9vh;
  color: #fffd96;
}
.number8 {
  margin-top: -10vh;
  font-weight: bold;
  font-size: 9vh;
  color: #fee395;
}
.number9 {
  margin-top: -10vh;
  font-weight: bold;
  font-size: 9vh;
  color: #ffc894;
}
.number10 {
  margin-top: -10vh;
  font-weight: bold;
  font-size: 9vh;
  color: #feaf94;
}
.number11 {
  margin-top: -10vh;
  font-weight: bold;
  font-size: 9vh;
  color:#fe9393;
}
.chartwrap {
}
.tdstyle {
  text-align: center;
  align-items: center;
  align-self: center;
}
.totalChartWrap{
  display: flex;
  align-items:baseline;
  margin-left: 5vh;
  margin-bottom: 1vh;
}
.left{
  font-weight: 600;
  color: #9afee0;
  font-size: 2vh;
  margin-right: -6vh;
  display:inline-block;
}
.right{
  font-weight: 600;
  color: #fe9393;
  font-size: 2vh;
  margin-left: -6vh;
  display:inline-block;
}
.resultswrap {
  align-items: center;
  background-color: #1a1e28;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  display: flex;
  flex: 1 1;
}
.TablePi {
  text-align: center;
  align-items: center;
  align-self: center;
}
.color0 {
  color: #9afee0;
}
.color1 {
  color: #9afdc7;
}
.color2 {
  color: #9afdae;
}
.color3 {
  color: #9cfe97;
}
.color4 {
  color:  #b4fe97;
}
.color5 {
  color: #cdfe97;
}
.color6 {
  color: #e7ff95;
}
.color7 {
  color: #fffd96;
}
.color8 {
  color: #fee395;
}
.color9 {
  color: #ffc894;
}
.color10 {
  color: #feaf94;
}

.color11 {
  color:#fe9393;
}

.thArtist0 {
  color: white;
  font-size: 240%;
}

.thArtist1 {
  color: white;
  font-size: 210%;
}

.thArtist2 {
  color: white;
  font-size: 200%;
}

.thArtist3 {
  color: white;
  font-size: 190%;
}

.thArtist4 {
  color: white;
  font-size: 180%;
}

.thArtist5 {
  color: white;
  font-size: 170%;
}

.thArtist6 {
  color: white;
  font-size: 160%;
}

.thArtist7 {
  color: white;
  font-size: 140%;
}

.thArtist8 {
  color: white;
  font-size: 130%;
}

.thArtist9 {
  color: white;
  font-size: 120%;
}

.thArtist10 {
  color: white;
  font-size: 110%;
}

.thArtist11 {
  color: white;
  font-size: 100%;
}

.thArtist12 {
  color: white;
  font-size: 90%;
}

.thArtist13 {
  color: white;
  font-size: 80%;
}

.thArtist14 {
  color: white;
  font-size: 70%
}

.thArtist15 {
  color: white;
  font-size: 60%
}

.thArtist16 {
  color: white;
  font-size: 50%
}

.thArtist17 {
  color: white;
  font-size: 50%
}

.thArtist18 {
  color: white;
  font-size: 40%
}

.thArtist19 {
  color: white;
  font-size: 40%
}

.thArtist20 {
  color: white;
  font-size: 30%
}

.thArtist21 {
  color: white;
  font-size: 30%
}

.fa-instagram {
  background: black;
}

.fa-tiktok {
  background: black;
}


.bottom-wrap {
  align-items: center;
  justify-content: center;
  margin-bottom: 2vh;
  margin-top: -2vh;
  background-color: #1a1e28;
  font-size: 1.4vh;
}

.AccountStatement {
  margin-bottom: 14vh;
  font-weight: bold;
  font-size: 150%;
  color: #DAAAF6;
}

.authwrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}
.top-logo {
  width: 10vh;
  margin-left: 2vh;
}

.frontstatement {
  font-size: 2vh;
  color: #babbbb;
  text-align: center;
  font-size: 23px;
  align-items: center;
  font-family: 'Wallop';
  font-style: normal;
  font-weight: 500;
  align-self: center;
  align-content: center;
  line-height: 130%;

}

.header2 {
  background-color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:center;
  font-size: 3vw;
  grid-column-gap: 2vh;
  -webkit-column-gap: 2vh;
          column-gap: 2vh;
  font-weight: 900;
  color: #DAAAF6;
}

.AccountScreen {
  text-align: center;
  align-items: center;
  flex: 1 1;
  height: 100%;
  width: 100%;
  background-color: black;
}

.App {
  text-align: center;
  background-color: black;
  height: 100%;
  flex: 1 1;
  min-height: 100vh;

}

.icons {
  background: #1a1e28;
  font-size: 180%;
  margin-left: 1vh;
}

.icons0 {
  background: #1a1e28;
  font-size: 180%;
  margin-right: 1vh;
}


.Author-wrapper {
  margin-top: 10%;
  text-align: center;
}

.Author {
  color: black;
  font-size: 3vw;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-body {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Header {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 10vw;
  font-weight: bolder;
  color: black;
}

.Header3 {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 5vw;
  font-weight: bolder;
  color: black;
}

.Header2 {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 5vw;
  font-weight: bold;
  color: black;
}

.App-link {
  color: #61dafb;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
}

body {
  background-color: #333;
  color: #eee;
  font-family: Helvetica, Arial;
  font-size: 3vmin;
}

.hidden {
  display: none;
}

/** Buttons **/


.btnn {
  border-radius: 2em;
  border: 0.3em solid #9afee0;
  color: #9afee0;
  background-color: #9afee0;
  border-color: #9afee0;
  cursor: pointer;
  font-size: 5vmin;
  padding: 0.7em 1.5em;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  color: #1a1e28;

}


/** Background **/

.background {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-position: center center;
  position: absolute;
  background-color: #1a1e28;
}

.main-wrapper {
  align-items: center;
  display: flex;
  height: 100%;
  margin: 0 auto;
  justify-content: center;
  position: relative;
  width: 80%;
  z-index: 1;
}

.container {
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: #1a1e28;
  flex: 1 1;
  margin: 0;
  padding: 0;
}
.account-background {
  background-color: #1a1e28;
  width: 100%;
  max-height: 80%;
}
.main-container {
  flex: 1 1;
  background-color: black;
}

.track-table {
  text-align: center;
  align-items: center;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
}

.img-row {
  height: 100px;
}

.trackwrap {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.85), transparent);
  padding-top: 6px;
}

.tableimg {
  font-size: 150%;
  font-weight: bolder;
  color: white;
  height: 50%;
  width: 50%;
  border-radius: 10%;
  background-position: center center;
  background-size: 100%;
  background-repeat: no-repeat;
  vertical-align: bottom;

}

.tableimg::before {
  opacity: .4;
}

.img-table {
  width: 100%;
  height: 100%;
  text-align: center;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
  border-spacing: 0px;
  margin-bottom: 1vh;
  margin-top: -3vh;
}

.trackth {}

.trackcss {
  color: white;
  font-size: 130%;

}

.emojicss {
  font-size: 450%;
}

.getemojiswrap {
  margin-top: -5vh;
}

.emojiswrap {
  margin-top: -12vh;
  margin-bottom: 5vh;

}

.watermarkcss {
  color: #9afee0;
  font-weight: bolder;
  font-size: 250%;
  display: inline-block;
  text-decoration: none;
  
}
.igtiktokwrap {
  align-self: center;
  align-items: center;
  margin-left: 4.5vh;
}

.logocss {
  max-width: 10%;
  height: auto;
}
.twinwrap {
  display: flex;
  flex-direction: row;
  grid-column-gap: 1vh;
  -webkit-column-gap: 1vh;
          column-gap: 1vh;
  margin-right: 1vh;
}
.nomarkers0 {
  text-decoration: none;
  color: #1a1e28;
}
.nomarkers1 {
  text-decoration: none;
  color: #fefffa;
}
.twinbtns0 {
  border-radius: 2em;
  background-color: #9afee0;
  padding-bottom: 0vh;
  padding-left: 1vh;
  padding-right: 1vh;
  width: 16vh;
  min-width: 15vh;
  height: 4vh;
  font-size: 1.5vh;
  font-weight: 600;
  border: #9afee0;
}
.twinbtns1 {
  border-radius: 2em;
  background-color: #30343d;
  padding-bottom: 0vh;
  padding-left: 1vh;
  padding-right: 1vh;
  width: 16vh;
  min-width: 15vh;
  height: 4vh;
  font-size: 1.5vh;
  font-weight: 600;
  border: #30343d;
}
@media only screen and (min-width: 768px) {
  .popularitywrapper{
    margin-left: 3vh;
    margin-top: 0vh;
  }
  .icons0 {
    background: #1a1e28;
    font-size: 250%;
    margin-left: -2vh;
  }
  .icons {
    background: #1a1e28;
    font-size: 250%;
    margin-left: 1vh;
  }
  .watermarkcss {
    color: #9afee0;
    font-weight: bolder;
    font-size: 300%;
    display: inline-block;
    text-decoration: none;
  }
  .top-logo {
    width: 10vh;
    margin-left: 2vh;
    margin-bottom: 1.5vh;
  }
  .frontstatement{
    margin-top: 1vh;
    font-size: 28px;
    margin-top: 2vh;
    line-height: 130%;
  }
  .igtiktokwrap {
    align-self: center;
    align-items: center;
    margin-left: 7vh;
  }
}
