@font-face {
  font-family: 'Wallop';
  src: local('Wallop'), url(./fonts/Wallop-Regular.otf) format('opentype');
}
.header0 {
  background-color: #1a1e28;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 3vw;
  column-gap: 2vh;
  font-weight: 900;
  height: 6vh;
  color: #9afee0;
}
.header1 {
  background-color: #1a1e28;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 10%;
  font-size: 8vw;
  font-family: 'Wallop';
  font-style: normal;
  font-weight: 600;
  color: #fefffa;
}
.nodec-buttons-text {
  color:black;
}
.nomarkers::visited {
  text-decoration: none;
  color: black;
}
.nomarkers {
  text-decoration: none;
}
.twinbtns{
  margin-top: 1vh;
  background-color: #DAAAF6;
  border-radius: 10%;
  width:15vh;
  height: 5vh;
  font-size: 1.7vh;
  font-weight: 600;
  border:#DAAAF6;
}
.popularitywrapper{
  margin-left: 3vh;
  margin-top: -10vh;
}
.tooltip{
  font-size: 2vh;
  color: #DAAAF6;
  margin-bottom: 5vh;
  margin-left: 1vh;
}
.prewrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
}
.number12 {
  margin-top: -10vh;
  font-weight: bold;
  font-size: 9vh;
  color: white;
}
.number0 {
  margin-top: -10vh;
  font-weight: bold;
  font-size: 9vh;
  color: #9afee0;
}
.number1 {
  margin-top: -10vh;
  font-weight: bold;
  font-size: 9vh;
  color: #9afdc7;
}
.number2 {
  margin-top: -10vh;
  font-weight: bold;
  font-size: 9vh;
  color: #9afdae;
}
.number3 {
  margin-top: -10vh;
  font-weight: bold;
  font-size: 9vh;
  color: #9cfe97;
}
.number4 {
  margin-top: -10vh;
  font-weight: bold;
  font-size: 9vh;
  color:  #b4fe97;
}
.number5 {
  margin-top: -10vh;
  font-weight: bold;
  font-size: 9vh;
  color: #cdfe97;
}
.number6 {
  margin-top: -10vh;
  font-weight: bold;
  font-size: 9vh;
  color: #e7ff95;
}
.number7 {
  margin-top: -10vh;
  font-weight: bold;
  font-size: 9vh;
  color: #fffd96;
}
.number8 {
  margin-top: -10vh;
  font-weight: bold;
  font-size: 9vh;
  color: #fee395;
}
.number9 {
  margin-top: -10vh;
  font-weight: bold;
  font-size: 9vh;
  color: #ffc894;
}
.number10 {
  margin-top: -10vh;
  font-weight: bold;
  font-size: 9vh;
  color: #feaf94;
}
.number11 {
  margin-top: -10vh;
  font-weight: bold;
  font-size: 9vh;
  color:#fe9393;
}
.chartwrap {
}
.tdstyle {
  text-align: center;
  align-items: center;
  align-self: center;
}
.totalChartWrap{
  display: flex;
  align-items:baseline;
  margin-left: 5vh;
  margin-bottom: 1vh;
}
.left{
  font-weight: 600;
  color: #9afee0;
  font-size: 2vh;
  margin-right: -6vh;
  display:inline-block;
}
.right{
  font-weight: 600;
  color: #fe9393;
  font-size: 2vh;
  margin-left: -6vh;
  display:inline-block;
}
.resultswrap {
  align-items: center;
  background-color: #1a1e28;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  display: flex;
  flex: 1;
}
.TablePi {
  text-align: center;
  align-items: center;
  align-self: center;
}
.color0 {
  color: #9afee0;
}
.color1 {
  color: #9afdc7;
}
.color2 {
  color: #9afdae;
}
.color3 {
  color: #9cfe97;
}
.color4 {
  color:  #b4fe97;
}
.color5 {
  color: #cdfe97;
}
.color6 {
  color: #e7ff95;
}
.color7 {
  color: #fffd96;
}
.color8 {
  color: #fee395;
}
.color9 {
  color: #ffc894;
}
.color10 {
  color: #feaf94;
}

.color11 {
  color:#fe9393;
}

.thArtist0 {
  color: white;
  font-size: 240%;
}

.thArtist1 {
  color: white;
  font-size: 210%;
}

.thArtist2 {
  color: white;
  font-size: 200%;
}

.thArtist3 {
  color: white;
  font-size: 190%;
}

.thArtist4 {
  color: white;
  font-size: 180%;
}

.thArtist5 {
  color: white;
  font-size: 170%;
}

.thArtist6 {
  color: white;
  font-size: 160%;
}

.thArtist7 {
  color: white;
  font-size: 140%;
}

.thArtist8 {
  color: white;
  font-size: 130%;
}

.thArtist9 {
  color: white;
  font-size: 120%;
}

.thArtist10 {
  color: white;
  font-size: 110%;
}

.thArtist11 {
  color: white;
  font-size: 100%;
}

.thArtist12 {
  color: white;
  font-size: 90%;
}

.thArtist13 {
  color: white;
  font-size: 80%;
}

.thArtist14 {
  color: white;
  font-size: 70%
}

.thArtist15 {
  color: white;
  font-size: 60%
}

.thArtist16 {
  color: white;
  font-size: 50%
}

.thArtist17 {
  color: white;
  font-size: 50%
}

.thArtist18 {
  color: white;
  font-size: 40%
}

.thArtist19 {
  color: white;
  font-size: 40%
}

.thArtist20 {
  color: white;
  font-size: 30%
}

.thArtist21 {
  color: white;
  font-size: 30%
}

.fa-instagram {
  background: black;
}

.fa-tiktok {
  background: black;
}


.bottom-wrap {
  align-items: center;
  justify-content: center;
  margin-bottom: 2vh;
  margin-top: -2vh;
  background-color: #1a1e28;
  font-size: 1.4vh;
}

.AccountStatement {
  margin-bottom: 14vh;
  font-weight: bold;
  font-size: 150%;
  color: #DAAAF6;
}

.authwrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}
.top-logo {
  width: 10vh;
  margin-left: 2vh;
}

.frontstatement {
  font-size: 2vh;
  color: #babbbb;
  text-align: center;
  font-size: 23px;
  align-items: center;
  font-family: 'Wallop';
  font-style: normal;
  font-weight: 500;
  align-self: center;
  align-content: center;
  line-height: 130%;

}

.header2 {
  background-color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:center;
  font-size: 3vw;
  column-gap: 2vh;
  font-weight: 900;
  color: #DAAAF6;
}

.AccountScreen {
  text-align: center;
  align-items: center;
  flex: 1;
  height: 100%;
  width: 100%;
  background-color: black;
}

.App {
  text-align: center;
  background-color: black;
  height: 100%;
  flex: 1;
  min-height: 100vh;

}

.icons {
  background: #1a1e28;
  font-size: 180%;
  margin-left: 1vh;
}

.icons0 {
  background: #1a1e28;
  font-size: 180%;
  margin-right: 1vh;
}


.Author-wrapper {
  margin-top: 10%;
  text-align: center;
}

.Author {
  color: black;
  font-size: 3vw;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-body {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Header {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 10vw;
  font-weight: bolder;
  color: black;
}

.Header3 {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 5vw;
  font-weight: bolder;
  color: black;
}

.Header2 {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 5vw;
  font-weight: bold;
  color: black;
}

.App-link {
  color: #61dafb;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
}

body {
  background-color: #333;
  color: #eee;
  font-family: Helvetica, Arial;
  font-size: 3vmin;
}

.hidden {
  display: none;
}

/** Buttons **/


.btnn {
  border-radius: 2em;
  border: 0.3em solid #9afee0;
  color: #9afee0;
  background-color: #9afee0;
  border-color: #9afee0;
  cursor: pointer;
  font-size: 5vmin;
  padding: 0.7em 1.5em;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  color: #1a1e28;

}


/** Background **/

.background {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-position: center center;
  position: absolute;
  background-color: #1a1e28;
}

.main-wrapper {
  align-items: center;
  display: flex;
  height: 100%;
  margin: 0 auto;
  justify-content: center;
  position: relative;
  width: 80%;
  z-index: 1;
}

.container {
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: #1a1e28;
  flex: 1;
  margin: 0;
  padding: 0;
}
.account-background {
  background-color: #1a1e28;
  width: 100%;
  max-height: 80%;
}
.main-container {
  flex: 1;
  background-color: black;
}

.track-table {
  text-align: center;
  align-items: center;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
}

.img-row {
  height: 100px;
}

.trackwrap {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.85), transparent);
  padding-top: 6px;
}

.tableimg {
  font-size: 150%;
  font-weight: bolder;
  color: white;
  height: 50%;
  width: 50%;
  border-radius: 10%;
  background-position: center center;
  background-size: 100%;
  background-repeat: no-repeat;
  vertical-align: bottom;

}

.tableimg::before {
  opacity: .4;
}

.img-table {
  width: 100%;
  height: 100%;
  text-align: center;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
  border-spacing: 0px;
  margin-bottom: 1vh;
  margin-top: -3vh;
}

.trackth {}

.trackcss {
  color: white;
  font-size: 130%;

}

.emojicss {
  font-size: 450%;
}

.getemojiswrap {
  margin-top: -5vh;
}

.emojiswrap {
  margin-top: -12vh;
  margin-bottom: 5vh;

}

.watermarkcss {
  color: #9afee0;
  font-weight: bolder;
  font-size: 250%;
  display: inline-block;
  text-decoration: none;
  
}
.igtiktokwrap {
  align-self: center;
  align-items: center;
  margin-left: 4.5vh;
}

.logocss {
  max-width: 10%;
  height: auto;
}
.twinwrap {
  display: flex;
  flex-direction: row;
  column-gap: 1vh;
  margin-right: 1vh;
}
.nomarkers0 {
  text-decoration: none;
  color: #1a1e28;
}
.nomarkers1 {
  text-decoration: none;
  color: #fefffa;
}
.twinbtns0 {
  border-radius: 2em;
  background-color: #9afee0;
  padding-bottom: 0vh;
  padding-left: 1vh;
  padding-right: 1vh;
  width: 16vh;
  min-width: 15vh;
  height: 4vh;
  font-size: 1.5vh;
  font-weight: 600;
  border: #9afee0;
}
.twinbtns1 {
  border-radius: 2em;
  background-color: #30343d;
  padding-bottom: 0vh;
  padding-left: 1vh;
  padding-right: 1vh;
  width: 16vh;
  min-width: 15vh;
  height: 4vh;
  font-size: 1.5vh;
  font-weight: 600;
  border: #30343d;
}
@media only screen and (min-width: 768px) {
  .popularitywrapper{
    margin-left: 3vh;
    margin-top: 0vh;
  }
  .icons0 {
    background: #1a1e28;
    font-size: 250%;
    margin-left: -2vh;
  }
  .icons {
    background: #1a1e28;
    font-size: 250%;
    margin-left: 1vh;
  }
  .watermarkcss {
    color: #9afee0;
    font-weight: bolder;
    font-size: 300%;
    display: inline-block;
    text-decoration: none;
  }
  .top-logo {
    width: 10vh;
    margin-left: 2vh;
    margin-bottom: 1.5vh;
  }
  .frontstatement{
    margin-top: 1vh;
    font-size: 28px;
    margin-top: 2vh;
    line-height: 130%;
  }
  .igtiktokwrap {
    align-self: center;
    align-items: center;
    margin-left: 7vh;
  }
}